import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable'; // tslint:disable-next-line
import {register, listen} from './serviceWorkerRegistration';
import React, {Suspense} from 'react';
import {DarkModeProvider} from 'mharj-react-dark';
import {NotificationProvider} from './NotificationProvider';
import {ServiceWorkerProvider} from './ServiceWorkerProvider';
import ThemeWrapper from './ThemeWrapper';

register();

Promise.all([
	import('./configureStore' /* webpackChunkName: "configurestore", webpackPreload: true */),
	import('react-dom' /* webpackChunkName: "react-dom", webpackPreload: true */),
	import('react-redux' /* webpackChunkName: "redux", webpackPreload: true */),
	import('redux-persist/integration/react' /* webpackChunkName: "persist", webpackPreload: true */),
	import('./App' /* webpackChunkName: "app", webpackPreload: true */),
	import('./reportWebVitals' /* webpackChunkName: "web-vitals", webpackPreload: true */),
	import('./lib/msal' /* webpackChunkName: "msal-lib", webpackPreload: true */),
	import('@azure/msal-react' /* webpackChunkName: "msal-react", webpackPreload: true */),
	import('./i18n' /* webpackChunkName: "i18n", webpackPreload: true */),
	import('./views/ErrorView' /* webpackChunkName: "error-view", webpackPreload: true */),
	import('./components/ErrorBoundary' /* webpackChunkName: "error-boundary-component", webpackPreload: true */),
	import('@azure/msal-browser' /* webpackChunkName: "msal-browser", webpackPreload: true */),
]).then(async (loaded) => {
	const [configureStore, ReactDOM, Redux, Persist, AppModule, reportWebVitals, msal, msalReact] = loaded;
	const pca = await msal.getMsalInstance();
	const {store, persistor} = configureStore.default();
	const App = AppModule.default;
	const MsalProvider = msalReact.MsalProvider;
	ReactDOM.render(
		<ServiceWorkerProvider listener={listen}>
			<Redux.Provider store={store}>
				<Persist.PersistGate loading={null} persistor={persistor}>
					<Suspense fallback={<div>Loading</div>}>
						<NotificationProvider>
							<DarkModeProvider>
								<ThemeWrapper>
									<MsalProvider instance={pca}>
										<App />
									</MsalProvider>
								</ThemeWrapper>
							</DarkModeProvider>
						</NotificationProvider>
					</Suspense>
				</Persist.PersistGate>
			</Redux.Provider>
		</ServiceWorkerProvider>,
		document.getElementById('root'),
	);
	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals.default(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals.default();
	// React is loaded, hide the crash warning
	document.getElementById('crash-warning')?.setAttribute('style', 'display: none');
});
