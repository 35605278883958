import {ThemeOptions} from '@mui/material';

const theme: ThemeOptions = {
	components: {
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: '30px',
					/* color: 'red', */
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					'&$focused $notchedOutline': {
						borderColor: '#049ad4',
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					'&$focused': {
						color: '#049ad4',
					},
				},
			},
		},
	},
	typography: {
		fontFamily: ['"Cantarell"', 'sans-serif'].join(','),
	},
	palette: {
		primary: {
			main: '#049ad4',
		},
		secondary: {
			main: '#212529',
		},
	},
};
export default theme;
