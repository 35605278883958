import React from 'react';
import {useDarkMode} from 'mharj-react-dark';
import {fiFI} from '@mui/x-date-pickers';
import {createTheme, CssBaseline, ThemeProvider} from '@mui/material';
import theme from './theme';

const ThemeWrapper: React.FC<{forceDark?: boolean}> = ({forceDark, children}) => {
	const isDarkMode = useDarkMode();
	return (
		<ThemeProvider
			theme={createTheme(
				{
					...theme,
					palette: {
						...theme.palette,
						mode: forceDark || isDarkMode ? 'dark' : 'light',
					},
				},
				fiFI,
			)}
		>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};
export default ThemeWrapper;
